      <template>
  <div id="addHazard">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>

      <div class="addProjects-content addProjects-content01">
        <span :class="(addtitle == '编辑重大危险源文件'&&filelist.length >0)?'edittile':'addtitle'">重大危险源文件</span>
        <div class="addProjects-input">
          <div class="img-list">
            <!-- action="https://jsonplaceholder.typicode.com/posts/" -->
            <el-upload class="picture-card"
                       :multiple="true"
                       :before-upload="beforeUpload"
                       accept=""
                       :action="
                baseUrl +
                '/Intelligence_Building_API/WeatherForecast/SavePictureList?type=1'
              "
                       :on-success="successfn"
                       :class="{ hiddenBtn: ((filelist.length >= 20)||(addtitle == '编辑重大危险源文件'&&filelist.length >0)) }"
                       :file-list="filelist"
                       list-type="picture-card"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="handleRemove"
                       :on-change="onchange"
                       :limit="20">
              <div class="imgbtn">
                <img src="@/assets/images/common/add.png"
                     alt=""
                     class="up-icon" />
                <span class="up-title">选择一个或多个交底文件</span>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  支持图片格式、pdf或者word，文件最大不超过200M
                </div>
              </template>
            </el-upload>
          </div>
        </div>
      </div>

      <div class="addProjects-bottom">
        <button @click="closefn">取消</button>
        <button @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="!filelist.length ? 'def' : ''">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
      <script>
import { reactive, toRefs, onMounted, onBeforeUnmount, computed } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import Ewang from "wangeditor";
import { baseUrl } from "@/js/util.js";

import { UpdateDangerousSource } from "@/js/safeDisclose.js";
import { DelPictureList } from "@/js/indexApi.js";
export default {
  props: {
    addfalse: Boolean,
    bonus: Number,
    addtitle: String,
    editdata: Object,
  },
  setup (props, { emit }) {
    const state = reactive({
      selectdata: "",
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      inputs2: "",
      inputs3: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      addtitle: "",
      editid: "",
      num: 0,
      showstyle: false,
      filelist: [],
      fileimg: null,
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        },
      ],
    });
    const closefn = () => {
      let datas = [];
      console.log(111, state.filelist.length);
      if (state.filelist.length) {
        state.filelist.forEach((item, index) => {
          datas.push(item.response[0]);
        });
        if (state.addtitle == "编辑重大危险源文件") {
        } else {
          delDoc(datas);
        }
      }
      emit("addfalsefn");
    };

    const cdata = getStorage("bscdata");
    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {
        ElMessage({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        // let {Code,Message} = res.data;
        // if (Code == 1) {

        // } else {
        //   ElMessage({
        //     showClose: true,
        //     message: Message,
        //     type: "error",
        //   });
        // }
      });
    };
    const methods = {
      onchange: (flie) => {
        console.log(flie, 12121, state.fileList);
        // if(fileSuffix)
      },
      // 图片
      beforeUpload: (file) => {
        var FileExt = file.name.replace(/.+\./, "");
        if (['jpg', 'png', 'jpeg', 'pdf', 'doc', 'docx'].indexOf(FileExt.toLowerCase()) === -1) {
          ElMessage({
            showClose: true,
            type: 'warning',
            message: '请上传后缀名为jpg、png、jpeg、pdf、doc、docx的附件！'
          });
          return false;
        }
        const is1M = file.size / 1024 / 1024 < 200; // 限制小于3M

        if (!is1M) {
          ElMessage({
            showClose: true,
            message: "大小不可超过200M",
            type: "error",
          });
        }
        return is1M;
      },
      // 上传图片
      successfn: (response, file, fileList) => {
        // 图片上传成功
        state.updatalistnum++;
        console.log(response, file, fileList, 111);

        // file.newing=file.response
        file.newname = file.name;
        const fileSuffix = file.newname.substring(
          file.newname.lastIndexOf(".") + 1
        );
        // const filename = file.newname.split(".")[0];
        const filename = file.name;

        file.typeimg = fileSuffix;
        file.filename = filename;
        if (fileSuffix == "doc" || fileSuffix == "docx") {
          file.url = require("@/assets/images/common/word.png");
        } else if (fileSuffix == "pdf") {
          file.url = require("@/assets/images/common/pdf.png");
        }
        console.log(file, 222, fileSuffix);
        state.filelist = fileList;
        // state.fileimg=response.join(',')
        state.imglist = [];
      },
      handlePictureCardPreview: (file) => {
        // 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemove: (file, fileList) => {
        // 删除图片
        state.updatalistnum++;
        let datas = [...file.response];
        state.filelist = fileList;
        state.imglist = [];
      },

      // 添加重大危险源文件
      UpdateDangerousSource: () => {
        let datas = [];

        if (state.addtitle == "编辑重大危险源文件") {
          console.log(state.filelist, 1212121);
          datas = [
            {
              id: state.editid,
              name: state.filelist[0].name,
              imgUrl: state.filelist[0].response[0],
              // type: 1,

              user: cdata.uid,
              projectID: cdata.pid,
            },
          ];
        } else {
          state.filelist.forEach((item, index) => {
            datas.push({
              name: item.filename,
              imgUrl: item.response[0],
              // type: 1,

              user: cdata.uid,
              projectID: cdata.pid,
            });
          });
        }

        UpdateDangerousSource(datas).then((res) => {
          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addfalsefn");
            emit("GetMachineryStatisticsList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },

      AddExtractApplyfn: () => {
        if (!state.filelist.length) {
          return;
        }

        methods.UpdateDangerousSource();
      },
    };

    onMounted(() => {
      console.log(props);

      state.addtitle = props.addtitle;
      if (state.addtitle == "编辑重大危险源文件") {
        state.editid = props.editdata.ID;
        state.selectdata = props.editdata.SecurityCategory;
        let obj = {
          name: props.editdata.Name,
          url: baseUrl + props.editdata.ImgUrl,
          response: [props.editdata.ImgUrl],
        };
        const fileSuffix = obj.response[0].substring(
          obj.response[0].lastIndexOf(".") + 1
        );
        // const filename = obj.response[0].split(".")[0];
        const filename = props.editdata.Name;
        obj.typeimg = fileSuffix;
        obj.filename = filename;

        if (fileSuffix == "doc" || fileSuffix == "docx") {

          obj.url = require("@/assets/images/common/word.png");
        } else if (fileSuffix == "pdf") {

          obj.url = require("@/assets/images/common/pdf.png");
        }

        state.filelist.push(obj);
      }
    });

    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
      <style lang="scss" scoped>
#addHazard {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 656px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      overflow: hidden;
      &:deep(.img-list) {
        .picture-card {
          position: relative;
        }

        .el-upload-list {
          // display: none;
          max-height: 400px;
          overflow-y: auto;
        }

        .el-upload__tip {
          position: absolute;
          top: -25px;
        }

        .imgbtn {
          width: 200px;
          height: 32px;
          position: relative;
          background: rgba(0, 180, 255, 0.1);
          border-radius: 4px;
          border: 1px solid #0083ff;
          margin-top: 2px;
          box-sizing: border-box;
        }
        .up-title {
          width: 180px;
          height: 32px;
          display: inline-block;
          font-size: 14px;
          position: absolute;
          bottom: -6px;
          left: 19px;
          color: rgba(0, 131, 255, 1);
        }
        .up-icon {
          width: 14px;
          height: 14px;
          position: absolute;
          left: 10%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        // width: 81px;
        //     height: 63px;
        .el-upload-list--picture-card {
          display: flex;

          margin-top: 50px;
          flex-wrap: wrap;
          .el-upload-list__item {
            width: 83px;
            height: 90px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 200px;
          height: 32px;
          position: relative;
          background: #f3f3f3;
          border: none;
          border-radius: 0;
        }

        .el-upload {
          position: absolute;
          top: -52px;
          left: 0px;
          .up-title {
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }

      span {
        display: inline-block;
        width: 110px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addProjects-content01 {
    width: 600px;

    position: relative;
    .addProjects-input {
      left: 110px;
    }
    .addtitle {
      position: absolute;
      top: 27px;
      display: inline-block;
      width: 110px;
      padding-right: 11px;
      box-sizing: border-box;
      text-align: right;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
    }

    .edittile {
      position: absolute;
      top: 101px;
      display: inline-block;
      width: 110px;
      padding-right: 11px;
      box-sizing: border-box;
      text-align: right;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>